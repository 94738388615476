import React ,{useState,useEffect} from 'react'
import { ReactTransliterate } from 'react-transliterate'
import { delTotaluserAction, editTotalUserset, errorclr, getAllTotaluser, setTotalUser } from '../../redux/actions/totalUserAction'
import {toast} from 'react-toastify'
import {useDispatch,useSelector} from 'react-redux'
import OtpInput from 'react-otp-input';
import './userdata.css'

const Userdata = () => {

    const [regName,setRegName] = useState('')
    const formdata = new FormData()
    const [number,setNumber] = useState('')
    const [area,setArea] = useState('')
    const [business,setBusiness] = useState('')
    const [edit, setedit] = useState(false)
    const [dataSearch,setDataSearch] = useState('')
    const dispatch = useDispatch()
    const {error,alltotaluser} = useSelector(state=>state.totalUserstate);
    const onSubmitHandler = (e) =>{
        e.preventDefault();
        if(number &&regName&&area){
          console.log(number)
          console.log(typeof(number))
          formdata.append("name",regName)
          formdata.append("number",number)
          formdata.append("area",area)
          formdata.append("business",business)
          if(edit){
            dispatch(editTotalUserset(number,formdata))
          }
          else{
            dispatch(setTotalUser(formdata))
          }
          setNumber('')
          setArea('')
          setBusiness('')
          setRegName('')
          setedit(false)
        }
        else{
          toast("check input", {
            position:toast.POSITION.BOTTOM_CENTER,
            type:'error'
        })
        }
      }
    useEffect(()=>{
        dispatch(getAllTotaluser)
    },[])
    
    const delTotaluser = (id) =>{
        console.log(id)
        if(window.confirm('can you delete userData')){
          dispatch(delTotaluserAction(id))
        }
        else{
          console.log('sorry not deleted...')
        }

    }

    const editTotalUser = (number,name,business,area)=>{
      console.log(number,name,business,area)
      setRegName(name)
      setNumber(number)
      setBusiness(business)
      setArea(area)
      setedit(true)
    }
    const inputStyle = number.length >= 10 
    ? { border: '#00ff00 solid 4px', outline: 'none',borderRadius:'10px' } 
    : {};

  return (
    <div className='userdata'>
        <div className="datamain">
            <div className="userRight">
                <h3>User data Register</h3>
            <form onSubmit={onSubmitHandler}>
            <div className='regInput'>
              <div>
                <input type='text' style={inputStyle} maxLength='10' autoComplete='off'  value={number}  onChange={(e)=>setNumber(e.target.value)} placeholder='Number' name='number'/>
                
              </div>
              <ReactTransliterate value={regName} autoComplete='off' onChangeText={(text) => {setRegName(text)}} name='regName'placeholder='Name' lang='ta'/>
              <ReactTransliterate value={business} autoComplete='off' onChangeText={(text) => {setBusiness(text)}} name='regName'placeholder='Business' lang='ta'/>
              <ReactTransliterate value={area} autoComplete='off' onChangeText={(text) => {setArea(text)}} name='regName'placeholder='Area' lang='ta'/>
              <button type='submit'>submit</button>
            </div>
          </form>
            </div>
            <div className="userLeft">
                <ReactTransliterate value={dataSearch} autoComplete='off' onChangeText={(text) => {setDataSearch(text)}} name='search'placeholder='search' lang='ta' size={50} style={{padding:'10px'}}/>
                <div className="userLeftdiv">
                    {alltotaluser&&
                    
                        alltotaluser.data.slice(0).reverse().filter(u=> (typeof u.name === 'string' && u.name.toLowerCase().includes(dataSearch.trim().toLowerCase())) ||
                        (typeof u.number === 'number' && u.number.toString().includes(dataSearch.trim()))).map(u=>

                        <div className="userLeftsmalldiv" key={u.number}> 
                        <div>
                            <h4>{u.name}</h4>
                            <h5>({u.business})</h5>
                            <h5>{u.number}</h5>
                            <h6>{u.area}</h6>
                        </div>                       
                        <div>
                            <button onClick={()=> editTotalUser(u.number,u.name,u.business,u.area)} style={{background:'green'}}>Edit</button>
                            <button onClick={() =>delTotaluser(u.number)} style={{background:'#d60000'}}>Delete</button>
                        </div>
                        
                        </div>

                        )
                    }
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Userdata