import React, { useEffect } from 'react'
import { ReactTransliterate } from 'react-transliterate'
import { useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { setTotalUser } from '../redux/actions/totalUserAction'
import './register.css'
import preImage from './asset/image/pre.png'
import {toast} from 'react-toastify'
import { errorclr } from '../redux/actions/totalUserAction'
import registerAudio from './asset/register.mp3';
import alreadyAudio from './asset/already.mp3';
import Swal from 'sweetalert2'

export const Register = () => {
  const [regName,setRegName] = useState('')
  const formdata = new FormData()
  const [number,setNumber] = useState()
  const [area,setArea] = useState('')
  const [business,setBusiness] = useState('')
  const dispatch = useDispatch()
  const {error,alltotaluser,loading} = useSelector(state=>state.totalUserstate);
  const onSubmitHandler = (e) =>{
    e.preventDefault();
    if(number &&regName&&area){
      console.log(number)
      console.log(typeof(number))
      formdata.append("name",regName)
      formdata.append("number",number)
      formdata.append("area",area)
      formdata.append("business",business)
      formdata.append("notneed",'true')
      dispatch(setTotalUser(formdata))
      setNumber('')
      setArea('')
      setBusiness('')
      setRegName('')
      
    }
    else{
      toast("check input", {
        position:toast.POSITION.BOTTOM_CENTER,
        type:'error'
    })
    }

  }
  useEffect(()=>{
    if(error){

      Swal.fire({
        title: "already data register this number",
        text: "click ok to exit",
        icon: "error"
      });
      toast("account already have contact suruthi moinote team", {
        position:toast.POSITION.BOTTOM_CENTER,
        type:'error'})
        var audio = new Audio(alreadyAudio);
        audio.play();
        dispatch(errorclr)
      }
      if( alltotaluser && alltotaluser.success == 'register'){
        var audio = new Audio(registerAudio);
      audio.play();
      Swal.fire({
        title: "successfully  register",
        text: "click ok to exit",
        icon: "success"
      });
      dispatch(errorclr)
      }
  },[error,dispatch,alltotaluser])

  const inputStyle = number && number.length >= 10 
    ? { border: '#00ff00 solid 4px', outline: 'none'} 
    : {};

  return (
    <div className="regContainer">
      <div className="topReg">
        <div className="topRegtxt">
          <h4>உங்கள் விவரங்களை முன்கூட்டியே பதிவு செய்திருந்தால், உங்கள் மொய்யை எளிதாகப் பதிவு செய்யலாம்</h4>
        </div>
      </div>
      <div className="main">
        
        <div className="formReg">
          <form onSubmit={onSubmitHandler}>
            <div className='regInput'>
              <h1 style={{color:'#5dade2',padding:'1px'}}>Suruthi moi note</h1>
              <h2 style={{color:'#5dade2',padding:'1px'}}>Suruthi Digital Flex Rameswaram 04573-299622</h2>
              <h3 style={{color:'#f5b041',padding:'1px'}}>register</h3>
              <div>
                <input type='text'maxLength='10' style={inputStyle} value={number}  onChange={(e)=>setNumber(e.target.value)} placeholder='Number' name='number'/>
              </div>
              <ReactTransliterate value={regName} onChangeText={(text) => {setRegName(text)}} name='regName'placeholder='Name' lang='ta'/>
              <ReactTransliterate value={business} onChangeText={(text) => {setBusiness(text)}} name='regName'placeholder='Business' lang='ta'/>
              <ReactTransliterate value={area} onChangeText={(text) => {setArea(text)}} name='regName'placeholder='Area' lang='ta'/>
              <button type='submit'>submit</button>
            </div>
          </form>
        </div>
        
        <div className="topRegImage">
            <img src={preImage} alt="pre image" width='100%' height='auto' />
        </div>
      </div>
      
    </div>
  )
}
