import React , { useEffect, useState ,useRef, Fragment } from 'react'
import './home.css'
import Loading from './minicomponent/loading/Loading'
import {useDispatch, useSelector} from 'react-redux'
import { createuser, getUser ,deleteCus,adupdatemoi,errorclr, clearUser} from '../../../redux/actions/userAction'
import {Outlet , Link, useParams, useNavigate} from 'react-router-dom'
import Cookies from 'universal-cookie'
import { ReactTransliterate } from "react-transliterate";
import { toast } from 'react-toastify'
import BookmarksIcon from '@mui/icons-material/Bookmarks';
const Home = () => {

    useEffect(()=>{
        dispatch(clearUser)
    },[])
    const navigator = useNavigate()
    useEffect(()=>{
        let cookies = new Cookies()
        cookies.remove('operator',{path:'/'})
        dispatch(getUser)
    },[])
    const [userData , setUserdata] = useState({
        username:"",
        subname:"",
        password:"",
        number:"",
        address:"",
        email:"",
        function:"",
        functionside:""
    })
    const [searchdata,setSearchdata] = useState('')
    const dispatch = useDispatch()
    const {id}  = useParams()
    const {loading,user} = useSelector((state)=>state.userstate)
    const [Edit,setEdit ] = useState(false)
    
    const onsubmitHandler = (e) =>{
        e.preventDefault()
        if(Edit === true)
        {
            if(userData.username && userData.number && userData.address && userData.subname && userData.function){
                const formData = new FormData()
                formData.append("username",userData.username)
                formData.append("subusername",userData.subname)
                formData.append("number",userData.number)
                formData.append("address",userData.address)
                formData.append("email",userData.email)
                formData.append("funtype",userData.function)
                formData.append("funside",userData.functionside)
                dispatch(adupdatemoi(id,formData))
                setUserdata({
                    username:"",
                    subname:"",
                    password:"",
                    number:"",
                    address:"",
                    email:"",
                    function:"",
                    functionside:""
                })
            }
            else{
                toast("completed required Tag ", {
                    position:toast.POSITION.BOTTOM_CENTER,
                    type:'error',
                    onOpen:()=> {dispatch(errorclr)}
                })
                return
            }
            setEdit(false)
            navigator("/main")
        }
        else{
            console.log('hi')
            if(userData.username && userData.password && userData.number && userData.address && userData.subname){
                console.log('if')
                const formData = new FormData()
                formData.append("username",userData.username)
                formData.append("subusername",userData.subname)
                formData.append("password",userData.password)
                formData.append("number",userData.number)
                formData.append("address",userData.address)
                formData.append("email",userData.email)
                formData.append("funtype",userData.function)
                formData.append("funside",userData.functionside)
                dispatch(createuser(formData))
                setUserdata({
                    username:"",
                    subname:"",
                    password:"",
                    number:"",
                    address:"",
                    email:"",
                    function:"",
                    functionside:""
                })
            }
            else{
                toast("completed required Tag ", {
                    position:toast.POSITION.BOTTOM_CENTER,
                    type:'error',
                    onOpen:()=> {dispatch(errorclr)}
                })
                return
            }
        }
        
    }
    
    const delcus = (e) => {
        if(window.confirm('Want to Delete')){
            dispatch(deleteCus(e.target.value))
            setUserdata({
                username:"",
                subname:"",
                password:"",
                number:"",
                address:"",
                email:"",
                function:"",
                functionside:""
            })
        }
    }

        const editcus = (id,name,number,address,email,subname,functio,functionside) =>{
            setUserdata({
                username:name,
                subname:subname,
                number:number,
                address:address,
                email:email,
                function:functio,
                functionside:functionside
            })
            console.log(id)
            if(id)
            {
                navigator("/main/"+id)
            }
            setEdit(true)
            
        }

        const godemo = (e) =>{
            e.preventDefault()
            navigator('/main/edit/650d2a3afbfbaf34ea72bf78')
        }

    

    return (
        <Fragment>
    {
    //   loading ? <Loading/>:
    <div className="home">
            <div className="homeCon">
                <div className="left">
                    <div className="top">
                        <h3>New Register</h3>
                        <form onSubmit={onsubmitHandler} encType='multipart/form-data'>
                            <div className="input">
                                <div className="inputIn">
                                    <div className="input_left">
                                        <div className="name">
                                            <label htmlFor="name">Name</label>
                                            <ReactTransliterate id='name' value={userData.username} onChangeText={(text) => setUserdata(prevData => ({ ...prevData, username: text }))} lang='ta' name='username' placeholder='Enter Name' autoComplete='off'/>
                                        </div>
                                        <div className="subnamke">
                                            <label htmlFor="subname">SubName</label>
                                            <ReactTransliterate id='subname' value={userData.subname} onChangeText={(text) => setUserdata(prevData => ({ ...prevData, subname: text }))} lang='ta' name='subname' placeholder='Enter subname' autoComplete='off'/>
                                        </div>
                                        <div className="email">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" onChange={(e) => setUserdata({...userData,[e.target.name]:e.target.value})} value={userData.email} id='email' name='email' placeholder='Enter email'/>
                                        </div>
                                        <div className="password">
                                            <label htmlFor="password">Password</label>
                                            <input type="text" onChange={(e) => setUserdata({...userData,[e.target.name]:e.target.value})} value={userData.password} id='password' name='password' placeholder='Enter Password'/>
                                        </div>
                                        

                                    </div>
                                    <div className="input_right">
                                        <div className="number">
                                            <label htmlFor="number">Number</label>
                                            <input type="number" onChange={(e) => setUserdata({...userData,[e.target.name]:e.target.value})} value={userData.number} id='number' name='number' placeholder='Enter Number'/>
                                        </div>
                                        <div className="function">
                                            <label htmlFor="function">Function</label>
                                            <ReactTransliterate id='function' value={userData.function} onChangeText={(text) => setUserdata(prevData => ({ ...prevData, function: text }))} lang='ta' name='function' placeholder='Enter function' autoComplete='off'/>
                                        </div>
                                        <div className="functionside">
                                            <label htmlFor="functionside">Function location</label>
                                            <ReactTransliterate id='functionside' value={userData.functionside} onChangeText={(text) => setUserdata(prevData => ({ ...prevData, functionside: text }))} lang='ta' name='functionside' placeholder='Enter functionside' autoComplete='off'/>
                                        </div>
                                        
                                        <div className="area">
                                            <label htmlFor="location">location</label>
                                            <ReactTransliterate id='address' value={userData.address} onChangeText={(text) => setUserdata(prevData => ({ ...prevData, address: text }))} lang='ta' name='address' placeholder='Enter address' autoComplete='off'/>
                                        </div>
                                    </div>
                                </div>
                                <button type='submit'>submit to register</button>
                            </div>
                        </form>
                    </div>
                    <div className="bottom">
                        <div className="totalcus">

                        </div>
                    </div>
                </div>
                <div className="right">
                    <h3 style={{display:'inline-block'}}>Cutomer List</h3> <button onClick={godemo} style={{display:'inline-block',background:'none',border:'none',padding:'10px'}}><BookmarksIcon/></button>
                    <ReactTransliterate id='searchData' value={searchdata} onChangeText={(text) => setSearchdata(text)} lang='ta' name='searchData' placeholder='search data' autoComplete='off'/>
                    <ul>
                        <li>
                            <div>
                                
                                {user && user.getCus.filter(list=>list.username.includes(searchdata.trim())).slice().reverse().map(u=>
                                                                        
                                    <div className="detail">
                                        <div>
                                        <Link to={`/main/edit/${u._id}`}>
                                            <h4>{u.username}</h4>
                                            <h5>{u.number}</h5>
                                            <p>{u.address}</p>
                                        </Link>            
                                        </div>
                                    
                                    {/* <button className='debtn' onClick={delcus} value={u._id}>delete</button> */}
                                    <button className='edbtn'  value={u._id} onClick={e=>editcus(u._id,u.username,u.number,u.address,u.email,u.subusername,u.funtype,u.funside)}>edit</button>
                                    </div>
                                )}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <Outlet />
    </div>
}
</Fragment>
  )
}

export default Home
