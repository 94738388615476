  import React, { useEffect, useState } from 'react'
  import './customerstyle.css'
  import { useDispatch, useSelector } from 'react-redux'
  import { logoutuser } from '../../redux/actions/userAction'
  import Countamount from './Countamount'
  import { ReactTransliterate } from 'react-transliterate';
  import {Cookies} from 'react-cookie'
  import {useNavigate} from 'react-router-dom'
  import { pdfgen } from '../../redux/actions/userAction'
  import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
  import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
  import Loading from '../mainpage/asset/minicomponent/loading/Loading'
  const CustomerView = () => {
    const [userData,setUserdata] = useState([])
    const dispatch = useDispatch()
    const [infoShow,setInfoShow] = useState(false) 
    const cookies = new Cookies();
    const navigate  = useNavigate()
    const usercookies =  cookies.get('token')
    const [search, setSearch] = useState('');
    let [amount,setamount] = useState(0)
    let {loading ,user} = useSelector((state)=>state.userstate)
    const {area} = useSelector(state=>state.userstate)
    let num = 0
    const fulltotal = (e) =>{
      setamount(amount+e.target.value)
      console.log(e)
      console.log(e.target.value)
    }


    useEffect(()=>{
      if(user && !area){
        console.log(user.cus._id)
        dispatch(pdfgen(user.cus._id))
      }
      if(userData.length == 0 ){
        if (area && area.area) {
        area.area.map(a => {
          if(user){
            user.cus.moiList.map((list,index)=>{
              if(a.area == list.area.trim()){
                list = {...list,rollNum:num++}
                setUserdata(prepdfdata=>[...prepdfdata,list])
              }
            })
          }
        });
      }
      console.log(userData)
    }
    },[user,userData,area])

    const logout = () => {
      dispatch(logoutuser)
      navigate('/login')
    }
    const handleInfo = () =>{
      infoShow?setInfoShow(false):setInfoShow(true)
    }
    
    return (
      <>
      {loading ? <Loading/> :
      <>
      <div className='customerView'>
          <div className="myProfile">
            <div className='brand'>
              <h4>Suruthi Moinote</h4>
            </div>
            <div className='mine'>
              
              <div>
                {user && 
                <div>
                  <h4>{user.cus.username}</h4>
                  <h5>{user.cus.email}</h5>
                </div>
                }
                <button onClick={logout}>logout</button>

              </div>
            </div>
            {user &&
            <div className="mioInfo">
              <div className='moiInfoArrow'>
                
                </div>
                <div className='InnermoiInfo' onClick={handleInfo}>
                  {infoShow ?
                  <div style={{width:'100%',display:'flex',justifyContent:'space-around'}}>
                  <div className='total_member'>
                    <h6>Total Member</h6>
                    <h3>{user.cus.moiList.length}</h3>
                  </div>
                  <div className='total_amount'>
                    {user && <Countamount siguser={user}/>}
                  </div>
                  <div>
                    <KeyboardDoubleArrowUpIcon />
                  </div>
                  </div>:

                  <div className='arrowDown' style={{display:'flex',justifyContent:'end',width:'100%'}}>
                    <KeyboardDoubleArrowDownIcon />
                  </div>}
                </div>
              
            
            </div>
              }
          </div>

          <div className="moilist">
              <div className="moilistIn">
              <ReactTransliterate
                          value={search}
                          onChangeText={(text) => setSearch(text)}
                          lang='ta'
                          name='searchcus'
                          className='searchviewcus'
                          placeholder='search here'
                          autoComplete='off'
                        />
                
                {/* <div className="lists">
                      
                
                {area && area.area.map((a) => {
                {user && user.cus.moiList.filter((u)=> u.area.includes(search.trim()) | u.name.includes(search.trim())).map(u=>{

                    <div className="list">
                    <div className="listLeft">
                    
                      
                          <div>
                          <h4>{u.name}</h4>
                          <h5>{u.business}</h5>
                          <h6>{u.area}</h6>
                          </div>
                        
                          
                      </div>
                      <div className="listLeft">
                          <h4 onChange={fulltotal}>{u.amount}</h4>
                      </div>
                  </div>
                  
                  
                })}
                })}
                </div> */}


              <div className="lists">
                
              
              
                
                <div >
                  
                  {userData && userData.filter((u)=> u.area.includes(search.trim()) | u.name.includes(search.trim())).map((u,index)=>
                    
                    
          
                  <div className="list">
                  <div className="listLeft">
                        <h3>
                          {u.rollNum + 1 }
                        </h3>
                        <div>
                        <h4>{u.name}</h4>
                        <h5>{u.business}</h5>
                        <h6>{u.area}</h6>
                        </div>
                      
                    </div>
                    <div className="listRight">
                        <h4 onChange={fulltotal}>{u.amount}</h4>
                    </div>
                </div> 
                  
                )
                
              }
                </div>
                

              
                </div>
                


                
              </div>
          </div>
      </div>
      </>
    }
    </> 
    )
  }

  export default CustomerView